import React from 'react';
import '../gallery_property.css';

function GalleryProperty({ title, image, onClick }) {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer'}}>
            <img className="gallery-image" src={image} alt={title} height={200} width={200} />
        </div>
    );
}

export default GalleryProperty;