import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LanguageSwitcher from '../components/LanguageSwitcher';
import ServiceBox from '../components/ServiceBox';
import QuestionBox from '../components/QuestionBox';
import PropertyCarousel from '../components/PropertyCarousel';
import ContactForm from '../components/ContactForm';
import ContactInfo from '../components/ContactInfo';
import CookieConsent from 'react-cookie-consent';
import logo from '../images/LogowithText.png';
import heroBg from '../images/Hero-bg.png';
import about1 from '../images/about-1.png';
import about2 from '../images/about-2.png';
import Map from '../components/Map'; // Import the Map component
import strapiService from '../services/strapiService';
import '../App.css';
import '../i18n';



const HomePage = ({ changeLanguage }) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const [mapLocation, setMapLocation] = useState({});
  const [faqs, setFAQS] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const faqs = await strapiService.getQuestions();
        setFAQS(faqs);
      } catch (error) {
        console.error('Error fetching FAQs', error);
      }
    };

    const fetchServices = async () => {
      try {
        const services = await strapiService.getServices();
        setServices(services);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    const fetchMapLocation = async () => {
      try {
        const mapLocation = await strapiService.getLocation();
        setMapLocation(mapLocation);
      } catch (error) {
        console.error('Error fetching map location:', error);
      }
    };

    fetchMapLocation();
    fetchServices();
    fetchFAQs();
  }, [language]);
  console.log(services);
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <div className="language-switcher">
            <LanguageSwitcher changeLanguage={changeLanguage} />
          </div>
        </div>
      </header>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myAwesomeCookieName"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          {t('cookie_consent_text')}
        </CookieConsent>
      </div>
      <div className="fullscreen-container" id="hero">
        <div className="inner-container logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="inner-container">
          <img src={heroBg} alt="Hero Background" className="hero-bg" />
        </div>
      </div>
      <div className="fullscreen-container grid-container">
        <div className="grid-item hero-title colored-title">
          {t('dedicated_management_services')}
        </div>
        <div className="grid-item">
          <p>{t('property_management_description')}</p>
        </div>
        <div className="grid-item">
          <img src={about1} alt="About" className="about-Image" />
        </div>
        <div className="grid-item">
          <img src={about2} alt="About" className="about-Image" />
        </div>
      </div>
      <header className="App-header">
        <div className="title">{t('what_we_offer')}</div>
        <div className="service-container">
          {
            services.map((service) => (
              <ServiceBox
                key={service.id}
                image={service.image}
                title={service.title}
                description={service.description}
                path={
                  service.title === t('property_management_title') ? '/services/property_management' : service.title === t('property_renovation_title') ? '/services/property_renovation' : '/services/property_purchase'
                }
              />
            )
            )
          }
        </div>
        <div className="property-container">
          <h2>{t('featured_properties')}</h2>
          <PropertyCarousel />
        </div>
        <div className="common-questions">
          <h2>{t('common_questions')}</h2>
          <div className="question-box-wrapper">
            {faqs.map(faq => (
              <QuestionBox key={faq.id} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
        <div className="footer">
          <ContactForm />
          <br />
          <ContactInfo />
        </div>
      </header>
      <Map location={{
        lat: mapLocation.latitude,
        lng: mapLocation.longitude,
      }} zoomLevel={15} /> {/* Add the Map component */}
    </div>
  );
};

export default HomePage;
