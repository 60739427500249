import React, { useState, useEffect } from 'react';
import { FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import '../ContactInfo.css'; // Assuming you have a CSS file for styles
import strapiService from '../services/strapiService';

const ContactInfo = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';
    const [contactInfo, setContactInfo] = useState({});

    // Debug: log the phone numbers to verify
    console.log('Landline:', t('contact_info_landline_number'));
    console.log('Mobile:', t('contact_info_mobile_number'));

    useEffect(() => {
        const contactInfo = async () => {
            try {
                const contactInfo = await strapiService.getContactInfo();
                setContactInfo(contactInfo);
            } catch (error) {
                console.error('Error fetching contact info:', error);
            }
        };
        contactInfo();
    }, []);

    return (
        <div className={`contact-info ${isRTL ? 'rtl' : ''}`}>
            <div className="contact-section">
                <h3>{t('contact_info_phone')}</h3>
                <p>
                    <a href={`tel:${t('contact_info_landline_number')}`}>
                        {contactInfo.landline}
                    </a>
                </p>
                <p>
                    <a href={`tel:${t('contact_info_mobile_number')}`}>
                        {contactInfo.phoneNumber}
                    </a>
                </p>
            </div>
            <div className="contact-section">
                <h3>{t('contact_info_address')}</h3>
                <p>{contactInfo.address}</p>
            </div>
            <div className="contact-section">
                <h3>{t('contact_info_email')}</h3>
                <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
            </div>
            <div className="contact-section">
                <h3>{t('contact_info_social')}</h3>
                <a href="https://www.instagram.com/msj_management/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={30} />
                </a>
            </div>
        </div>
    );
};

export default ContactInfo;
