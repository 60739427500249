import React from "react";
import { useState } from "react";

const Carousel = ({ media }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + media.length) < 0 ? 0 : (prevIndex - 1 + media.length) % media.length);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
    };

    return (
        <div style={{ position: 'relative', width: '300px', height: '300px' }}>
            <button onClick={handlePrev} style={{
                position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)',
                backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', border: 'none', cursor: 'pointer', padding: '10px',
                fontWeight: 'w900', fontSize: '20px', borderTopRightRadius: '8px', borderBottomRightRadius: '8px'
            }}>←</button>
            <img src={media[currentIndex]} alt={`Slide ${currentIndex}`} style={{ width: '100%', height: '100%', borderRadius: '12px'}} />
            <button onClick={handleNext} style={{
                position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)',
                backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', border: 'none', cursor: 'pointer', padding: '10px',
                fontWeight: 'w900', fontSize: '20px', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'
            }}>→</button>
        </div>
    );
};

export default Carousel;