import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeLanguage as changeLanguageAction } from '../redux/actions/languageActions'; // Adjust the import path as needed
import '../Header.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const language = sessionStorage.getItem('language') || 'en';
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
      dispatch(changeLanguageAction(language));
    }
    document.documentElement.lang = language;
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n, dispatch]);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(changeLanguageAction(lng));
    sessionStorage.setItem('language', lng);
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <div className="language-switcher">
      <button onClick={() => handleLanguageChange('en')} className="language-button">EN</button>
      <button onClick={() => handleLanguageChange('ar')} className="language-button">AR</button>
    </div>
  );
};

export default LanguageSwitcher;