import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from '../components/Header';
import ServicePageLayout from '../components/ServicePageLayout';
import ServiceImage from '../images/Service1.jpg';
import { useTranslation } from 'react-i18next';
import ContactForm from '../components/ContactForm';
import '../Header.css';
import strapiService from '../services/strapiService';
import GalleryProperties from '../components/gallery_properties';

function PropertyRenovationPage({ changeLanguage }) {
  const { i18n } = useTranslation();
  const [services, setServices] = useState([]);

  useEffect(() => {
    const language = sessionStorage.getItem('language');
    console.log('PropertyRenovationPage - from session storage:', language);
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language).then(() => {
        console.log('PropertyRenovationPage - Language set to:', language);
      });
    }

    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    const fetchServices = async () => {
      try {
        const services = await strapiService.getServices();
        setServices(services);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServices();
  }, [i18n.language]);

  const service = i18n.language == 'ar' ? services[2] : services[1];
  const title = service?.title;
  const description = service?.description;



  return (
    <div>
      <Header changeLanguage={changeLanguage} />
      <ServicePageLayout image={ServiceImage} title={title} description={description}>
        <GalleryProperties />
        <ContactForm />
      </ServicePageLayout>
    </div>
  );
}

export default PropertyRenovationPage;
