// PropertyManagementPage.js
import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from '../components/Header';
import ServicePageLayout from '../components/ServicePageLayout';
import ServiceImage from '../images/Service1.jpg';
import { useTranslation } from 'react-i18next';
import '../i18n';
import ContactForm from '../components/ContactForm';
import strapiService from '../services/strapiService';

function PropertyManagementPage({ changeLanguage }) {
  const { i18n } = useTranslation();
  const [services, setServices] = useState([]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    window.scrollTo(0, 0);  // Scroll to the top of the page when the component mounts
    const fetchServices = async () => {
      try {
        const services = await strapiService.getServices();
        setServices(services);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServices();
  }, [i18n.language]);

  console.log('changeLanguage in PMP:', changeLanguage);

  const service = services[0];
  const title = service?.title;
  const description = service?.description;


  return (
    <div>
      <Header changeLanguage={changeLanguage} />
      <ServicePageLayout image={ServiceImage} title={title} description={description}>
        <ContactForm />
      </ServicePageLayout>
    </div>
  );
}

export default PropertyManagementPage;
