import React, { useEffect, useState } from 'react';
import '../gallery.css';
import { useTranslation } from 'react-i18next';
import '../i18n';
import strapiService from '../services/strapiService';
import GalleryProperty from './gallery_property';
import Dialog from './dialog';


function GalleryProperties() {
    const { i18n } = useTranslation();
    const [galleryProperties, setGalleryProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);

    const handleGalleryPropertyClick = (galleryProperty) => {
        setSelectedProperty(galleryProperty);
    };

    const handleCloseDialog = () => {
        setSelectedProperty(null);
    };

    useEffect(() => {
        document.documentElement.lang = i18n.language;
        document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
        window.scrollTo(0, 0);  // Scroll to the top of the page when the component mounts
        const fetchGalleryProperties = async () => {
            try {
                const galleryProperties = await strapiService.getGalleryProperties();
                setGalleryProperties(galleryProperties);
            } catch (error) {
                console.error('Error fetching gallery properties:', error);
            }
        };
        fetchGalleryProperties();
    }, [i18n.language]);


    return (
        <div className="gallery-properties">
            {galleryProperties.map((galleryProperty) => (
                <GalleryProperty key={galleryProperty.id} title={galleryProperty.title} image={galleryProperty.image}
                    onClick={() => handleGalleryPropertyClick(galleryProperty)} />
            ))}
            <Dialog
                isOpen={selectedProperty !== null}
                onClose={handleCloseDialog}
                title={selectedProperty?.title}
                description={selectedProperty?.description}
                image={selectedProperty?.image}
                media={selectedProperty?.media}
            />
        </div>
    );
}


export default GalleryProperties;