import React from "react";
import Carousel from "./carousel";

const Dialog = ({ isOpen, onClose, title, description, media }) => {
    if (!isOpen) return null;
    return (
        <div style={{
            position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            height: '100vh',
            borderRadius: '80px',
        }}>
            <div style={{ backgroundColor: '#202020', color: 'white', padding: '20px', borderRadius: '18px' }}>
                <button onClick={onClose} style={{
                    float: 'right',
                    backgroundColor: 'orange', color: 'white',
                    borderRadius: '20px', paddingLeft: '10px',
                    paddingRight: '10px', paddingTop: '5px',
                    paddingBottom: '5px'

                }}>Close</button>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', flexDirection: 'column' }}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <Carousel media={media} />
                </div>
            </div>
        </div>
    );
};

export default Dialog;