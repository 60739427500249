import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import strapiService from '../services/strapiService';

export function PropertyCarousel() {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState(i18n.language === 'ar' ? 'rtl' : 'ltr');
  const [carouselImages, setCarouselImages] = useState([]);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      align: 'center',
      direction: direction,
      startIndex: direction === 'rtl' ? carouselImages.length - 1 : 0,
    },
    [Autoplay()]
  );

  const debouncedSetDirection = debounce(setDirection, 300);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await strapiService.getCarouselImages();
        setCarouselImages(images);
      } catch (error) {
        console.error('Error fetching carousel images:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const handleLanguageChange = () => {
      const newDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';
      debouncedSetDirection(newDirection);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
      debouncedSetDirection.cancel();
    };
  }, [i18n, debouncedSetDirection]);

  const reInitEmbla = useCallback(() => {
    if (emblaApi && emblaApi.canScrollPrev && emblaApi.canScrollNext) {
      emblaApi.reInit({ direction: direction });
      setTimeout(() => {
        emblaApi.scrollTo(direction === 'rtl' ? carouselImages.length - 1 : 0);
      }, 1000);
    }
  }, [emblaApi, direction, carouselImages.length]);

  useEffect(() => {
    reInitEmbla();
  }, [reInitEmbla]);

  useEffect(() => {
    if (emblaApi) {
      const onSelect = () => {
        const slides = emblaApi.slideNodes();
        if (slides.length > 0) {
          slides.forEach((slide) => slide.classList.remove('is-active'));
          const centralIndex = emblaApi.selectedScrollSnap();
          if (centralIndex >= 0 && centralIndex < slides.length) {
            slides[centralIndex].classList.add('is-active');
          }
        }
      };

      onSelect();
      emblaApi.on('select', onSelect);
    }
  }, [emblaApi, direction]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        {carouselImages.map((item, index) => (
          <div className="embla__slide" key={item.id}>
            <img src={item.image} alt={`property ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PropertyCarousel;