import axios from 'axios';
import { store } from '../redux/store'; // Ensure the store is correctly imported

const API_BASE_URL = 'https://cms.msjmanagement.com/api';
const AUTH_TOKEN = 'df238bc5c0fca7ce336e6d94c097ee162c62a7e266101b04d43f1ec77e9e07c9054a56d8b1701b1fb972403b4f0137bd197ad02323ace7847098b6a4c549d38158437c4293d1468b9999a113fff930e3c2c64339bdbceec87d208443eafbbcbce82aa552d00f9208f0360f092252d68a4f7f41c62161b698ebbd4934f1af6704'; // Replace with your actual token

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${AUTH_TOKEN}`,
  },
  params: {
    populate: '*',
  },
});

const getLocale = () => store.getState().language;

const strapiService = {
  getProperties: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/properties', {
        params: { locale }
      });
      return response.data.data.map(item => ({
        id: item.id,
        image: item.attributes.image?.data?.attributes?.url ? `https://cms.msjmanagement.com${item.attributes.image.data.attributes.url}` : '',
        description: item.attributes.description,
        title: item.attributes.title,
      }));
    } catch (error) {
      console.error('Error fetching properties from Strapi:', error);
      throw error;
    }
  },

  getGalleryProperties: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/gallery-properties', {
        params: { locale }
      });
      console.log('Fetched Gallery Properties:', response.data.data); // Log the response
      return response.data.data.map(item => ({
        id: item.id,
        image: item.attributes.image?.data?.attributes?.url ? `https://cms.msjmanagement.com${item.attributes.image.data.attributes.url}` : '',
        media: item.attributes.media?.data ? item.attributes.media.data.map(mediaItem => `https://cms.msjmanagement.com${mediaItem.attributes.url}`) : [],
        description: item.attributes.description,
        title: item.attributes.title,
      }));

    } catch (error) {
      console.error('Error fetching gallery properties from Strapi:', error);
      throw error;
    }
  },
  getServices: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/services', {
        params: { locale }
      });
      return response.data.data.map(item => ({
        id: item.id,
        image: item.attributes.image?.data?.attributes?.url ? `https://cms.msjmanagement.com${item.attributes.image.data.attributes.url}` : '',
        description: item.attributes.description,
        title: item.attributes.title,
      }));
    } catch (error) {
      console.error('Error fetching services from Strapi:', error);
      throw error;
    }
  },

  getQuestions: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/questions', {
        params: { locale }
      });
      return response.data.data.map(item => ({
        id: item.id,
        question: item.attributes.question,
        answer: item.attributes.answer[0].children.map(child => child.text).join(' '),
      }));
    } catch (error) {
      console.error('Error fetching questions from Strapi:', error);
      throw error;
    }
  },

  getContactForm: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/contact-form', {
        params: { locale }
      });
      return {
        id: response.data.data.id,
        namePlaceholder: response.data.data.attributes.namePlaceholder,
        emailPlaceholder: response.data.data.attributes.emailPlaceholder,
        messagePlaceholder: response.data.data.attributes.messagePlaceholder,
      }
    } catch (error) {
      console.error('Error fetching Contact form from Strapi:', error);
      throw error;
    }
  },

  getContactInfo: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/contact-info', {
        params: { locale }
      });
      return {
        id: response.data.data.id,
        landline: response.data.data.attributes.landline,
        phoneNumber: response.data.data.attributes.phoneNumber,
        email: response.data.data.attributes.email,
        address: response.data.data.attributes.address,
      }
    } catch (error) {
      console.error('Error fetching Contact info from Strapi:', error);
      throw error;
    }
  },

  getLocation: async () => {
    try {
      const response = await axiosInstance.get('/location');
      return {
        id: response.data.data.id,
        latitude: response.data.data.attributes.latitude,
        longitude: response.data.data.attributes.longitude,
      };
    } catch (error) {
      console.error('Error fetching locations from Strapi:', error);
      throw error;
    }
  },

  getCarouselImages: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/property-carousels', {
        params: { locale }
      });
      return response.data.data.map(item => {
        const imageUrl = item.attributes.Image?.data?.[0]?.attributes?.url;
        return {
          id: item.id,
          image: imageUrl ? `https://cms.msjmanagement.com${imageUrl}` : '',
        };
      });
    } catch (error) {
      console.error('Error fetching carousel images from Strapi:', error);
      throw error;
    }
  },



  getHeroSection: async () => {
    try {
      const locale = getLocale();
      const response = await axiosInstance.get('/heroes', {
        params: { locale }
      });
      console.log('Fetched Hero Section:', response.data.data); // Log the response
      return response.data.data;
    } catch (error) {
      console.error('Error fetching hero section from Strapi:', error);
      throw error;
    }
  },
};

export default strapiService;